export const FETCH_BANNERS_SUCCESS = "FETCH_BANNERS_SUCCESS";

const fetchBannersSuccess = categories => ({
  type: FETCH_BANNERS_SUCCESS,
  payload: categories
});

// fetch categories
export const fetchBanners = banners => {
  return dispatch => {
    dispatch(fetchBannersSuccess(banners));
  };
};
