import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import * as React from "react";
import ReactDOMServer from "react-dom/server";
import db from "../config/firebase";
import OrderConfirmation from "../pages/other/OrderConfirmation";
import { getTotalPriceCheckout } from "./checkout";
import { getDiscountPrice } from "./product";
const summaryCartItems = (cartItems) => {
  return cartItems.map((cartItem) => {
    const discount = getDiscountPrice(cartItem.price, cartItem.discount);
    return {
      cartItemId: cartItem.cartItemId,
      name: cartItem.name,
      productId: cartItem.id,
      price: cartItem.price,
      discount: cartItem.discount,
      quantity: cartItem.quantity,
      selectedProductColor: cartItem.selectedProductColor,
      selectedProductSize: cartItem.selectedProductSize,
    };
  });
};

async function asycronouseProcess(cartItem) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "products", cartItem.id)).then((snaphot) => {
      let product = snaphot.data();
      product["variation"].map((element) => {
        if (element.color === cartItem.selectedProductColor) {
          element.size.map((item) => {
            if (item.name === cartItem.selectedProductSize) {
              item.stock = item.stock - cartItem.quantity;
            }
            return item;
          });
        }
        return element;
      });

      updateDoc(doc(db, "products", cartItem.id), {
        variation: product["variation"],
      }).then(resolve());
    });
  });
}
async function decreseItemCount(cartItems) {
  var i;
  var j = cartItems.length;
  for (i = 0; i < j; i++) {
    await asycronouseProcess(cartItems[i]);
  }
}

export async function createFirebasePendingOrder(checkout) {
  return await setDoc(doc(db, "orders", "pending", "listOrders", checkout.id), {
    status: 0,
    cartItems: summaryCartItems(checkout.cartItems),
    infoUser: checkout.infoUser,

    discount: checkout.discount || "",
    createdAt: new Date(),
    id: checkout.id,
  });
}

export async function createOrder(checkout, result) {
  return new Promise(async (resolve, reject) => {
    getDoc(doc(db, "orders", "confirmed", "listOrders", checkout.id)).then(
      async (snapshot) => {
        if (snapshot.exists()) {
          reject("exists");
        } else {
          deleteDoc(doc(db, "orders", "pending", "listOrders", checkout.id));
          await decreseItemCount(checkout.cartItems);

          const docDataSummary = {
            status: 1,
            shipping: checkout.shipping,
            cartItems: summaryCartItems(checkout.cartItems),
            discount: checkout.discount || "",
            infoUser: checkout.infoUser,
            createdAt: new Date(),
            id: checkout.id,
            infoPayment:
              result !== undefined
                ? {
                    payedAmount: result?.amount,
                    payedDate: result?.created,
                    paymentMethod: result?.payment_method,
                    paymentStatus: result?.status,
                    paymentId: result?.id,
                    ...checkout?.infoFatturazione,
                  }
                : {
                    payedAmount: parseInt(
                      getTotalPriceCheckout(checkout) * 100
                    ),
                    paymentMethod: 1,
                    paymentStatus: "pending",
                    ...checkout?.infoFatturazione,
                  },
          };
          const docDataResult = {
            status: 1,
            shipping: checkout.shipping,
            cartItems: checkout.cartItems,
            discount: checkout.discount,
            infoUser: checkout.infoUser,
            createdAt: new Date(),
            id: checkout.id,
            infoPayment:
              result !== undefined
                ? {
                    payedAmount: result?.amount,
                    payedDate: result?.created,
                    paymentMethod: result?.payment_method,
                    paymentStatus: result?.status,
                    paymentId: result?.id,
                    ...checkout?.infoFatturazione,
                  }
                : {
                    payedAmount: parseInt(
                      getTotalPriceCheckout(checkout) * 100
                    ),
                    paymentMethod: 1,
                    paymentStatus: "pending",
                    ...checkout?.infoFatturazione,
                  },
          };
          setDoc(
            doc(db, "orders", "confirmed", "listOrders", checkout.id),
            docDataSummary
          )
            .then(() => {
              sendEmailConfirmation(docDataResult)
                .then((res) => {
                  resolve(docDataResult);
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        }
      }
    );
  });
}

const sendEmailConfirmation = async (docData) => {
  const element = <OrderConfirmation order={docData} />;
  // ReactDOMServer.renderToString(element);

  const emailData = {
    to: [docData.infoUser.email],
    message: {
      subject: "Conferma ordine",
      text: "Grazie per il tuo ordine!",
      html: renderMyMail(element),
    },
  };

  return await addDoc(collection(db, "mail"), emailData);
};

export const renderMyMail = (params) => {
  const reactHtmlString = ReactDOMServer.renderToString(params).replace(
    /[\r\n]+/gm,
    ""
  );
  //const reminifiedHtmlString = minify(reactHtmlString, { maxLineLength: 255, keepClosingSlash: true });
  return reactHtmlString;
};

export const PAYMENT_METHOD_ONLINE = "0";
export const PAYMENT_METHOD_CONSEGNA = "1";
export const SHIPPING_TYPE_RITIRO_IN_SEDE = "0";
export const SHIPPING_TYPE_CONSEGNA = "1";
