import React from "react";
import { getTotalCartItemsPrice } from "../../helpers/checkout";
import { SHIPPING_TYPE_RITIRO_IN_SEDE } from "../../helpers/payment";
import { getDiscountPrice, getProductColorPhoto } from "../../helpers/product";
export default function OrderConfirmation(props) {
  const [order, setOrder] = React.useState(props.order);
  let cartTotalPrice = 0;
  const css = `
  .banner-confirmation {
    background: #d4a0ac;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.confirmation-order-area {
    margin-top: 30px;
}
.confirmation-order-area.pr-100 {
    padding-right: 100px;
}
h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 20px;
    color: #000;
}
.your-order-wrap {
    padding: 38px 45px 44px;
    background: #f6f6f6;
    padding: 30px 20px 36px;
}
.your-order-wrap .your-order-product-info .your-order-top ul {
    display: flex;
    justify-content: space-between;
}
.your-order-wrap .your-order-product-info .your-order-top ul li {
    font-size: 16px;
    font-weight: 500;
    list-style: outside none none;
}
.your-order-wrap .your-order-product-info .your-order-middle {
    margin: 29px 0;
    padding: 19px 0 18px;
    border-top: 1px solid #dee0e4;
    border-bottom: 1px solid #dee0e4;
}
.your-order-wrap .your-order-product-info .your-order-middle ul li {
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px;
}
.your-order-wrap .your-order-product-info .your-order-bottom ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.your-order-wrap .your-order-product-info .your-order-bottom ul li {
    font-size: 14px;
    font-weight: 400;
    list-style: none;
}
.your-order-wrap .your-order-product-info .your-order-bottom ul li.your-order-shipping {
    font-size: 16px;
    font-weight: 400;
    color: #212121;
}
.your-order-wrap .your-order-product-info .your-order-total {
    margin: 18px 0 33px;
    padding: 17px 0 19px;
    border-top: 1px solid #dee0e4;
    border-bottom: 1px solid #dee0e4;
}
.your-order-wrap .your-order-product-info .your-order-total ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.your-order-wrap .your-order-product-info .your-order-total ul li.order-total {
    font-size: 18px;
    font-weight: 500;
    color: #212121;
}
.your-order-wrap .your-order-product-info .your-order-total ul li {
    font-size: 16px;
    font-weight: 500;
    list-style: outside none none;
    color: #d4a0ac;
}

.place-order > a, .place-order > button {
    font-weight: 500;
    line-height: 1;
    z-index: 9;
    display: block;
    width: 100%;
    padding: 18px 20px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    border: none;
    border-radius: 50px;
    background: none;
    background-color: #d4a0ac;
}
.place-order > a:hover, .place-order > button:hover {
    background-color: #333;
}
.confirmation-sub-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 12px;
}
.confirmation-sub-item .title {
    font-size: 16px;
    font-weight: 500;
    color: #212121;
}

`;

  return (
    <html>
      <head>
        <style type="text/css">{css}</style>
      </head>
      <body>
        <div>
          <div
            style={{
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/e-commerce-ddd26.appspot.com/o/logo.png?alt=media&token=94c5acdb-6176-4a25-bf25-aa423af27371"
              style={{ height: "50px" }}
            />
          </div>
          {order !== null ? (
            <div>
              <div className="confirmation-area pt-40 pb-100">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="banner-confirmation pt-100 pb-100 ">
                      <div className="fa pe-7s-check fa-4x"></div>
                      <h2>Grazie {order.infoUser.firstName}!</h2>
                      <h5
                        style={{
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          textAlign: "center",
                        }}
                      >
                        Il tuo ordine #{order.id} è stato inviato con successo
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="confirmation-order-area pl-150 pr-150 pb-100">
                  <h3>Riepilogo ordine</h3>
                  <div
                    className="your-order-wrap"
                    style={{
                      background: "#fff",
                      border: "1px solid #DEE0E4",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="your-order-product-info">
                      <div className="your-order-top">
                        <ul>
                          <li>Prodotti</li>
                          <li>Totale</li>
                        </ul>
                      </div>
                      <div className="your-order-middle">
                        <ul>
                          {order.cartItems.map((cartItem, key) => {
                            const discountedPrice = getDiscountPrice(
                              parseFloat(cartItem.price),
                              parseFloat(cartItem.discount)
                            );

                            const finalProductPrice = parseFloat(
                              cartItem.price
                            ).toFixed(2);
                            const finalDiscountedPrice =
                              parseFloat(discountedPrice).toFixed(2);

                            discountedPrice != null
                              ? (cartTotalPrice +=
                                  finalDiscountedPrice * cartItem.quantity)
                              : (cartTotalPrice +=
                                  finalProductPrice * cartItem.quantity);
                            return (
                              <li key={key}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                  }}
                                >
                                  <img
                                    width="65px"
                                    style={{ borderRadius: "8px" }}
                                    src={getProductColorPhoto(
                                      cartItem,
                                      cartItem.selectedProductColor
                                    )}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "start",
                                      justifyContent: "left",
                                      alignSelf: "flex-start",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    <span>
                                      {cartItem.name} X {cartItem.quantity}
                                    </span>
                                    <span>
                                      Colore: {cartItem.selectedProductColor}
                                    </span>
                                    <span>
                                      Taglia: {cartItem.selectedProductSize}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      alignItems: "end",
                                      justifySelf: "end",
                                      justifyContent: "end",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <span className="order-price">
                                      {discountedPrice !== null
                                        ? "€" +
                                          (
                                            finalDiscountedPrice *
                                            cartItem.quantity
                                          ).toFixed(2)
                                        : "€" +
                                          (
                                            finalProductPrice *
                                            cartItem.quantity
                                          ).toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="your-order-bottom">
                        <ul>
                          <li className="your-order-shipping">Subtotale</li>
                          <li>{getTotalCartItemsPrice(order.cartItems)}</li>
                        </ul>
                        <ul>
                          <li className="your-order-shipping">Spedizione</li>
                          <li>{"€" + order.shipping?.price.toFixed(2)}</li>
                        </ul>
                        {order.discount !== undefined && (
                          <div>
                            <ul>
                              <li className="your-order-discount">
                                Sconti {order.discount?.id}
                                <span
                                  data-text={order?.discount?.description}
                                  className="tooltipp"
                                >
                                  <i
                                    className="fa fa-info-circle"
                                    style={{
                                      paddingLeft: "4px",
                                      fontSize: "14px",
                                    }}
                                  ></i>
                                </span>
                              </li>
                              <li className="your-order-discount">
                                {"-€" + order?.discount?.valore}
                              </li>
                            </ul>
                            <ul>
                              <li className="your-order-discount-description">
                                {order?.discount?.name}
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="your-order-total">
                        <ul>
                          <li className="order-total">Totale</li>
                          <li>
                            {"€" +
                              (order.infoPayment.payedAmount / 100).toFixed(2)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="col-lg-4 col-md-6 col-xs-12">
                      <div className="confirmation-sub-item">
                        <div className="title">Email</div>
                        <div>{order.infoUser.email}</div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-xs-12">
                      <div className="confirmation-sub-item">
                        <div className="title">Metodo di spedizione</div>
                        <div>
                          {order.shipping?.type === SHIPPING_TYPE_RITIRO_IN_SEDE
                            ? "Ritiro in sede: Per info contattare il numero: +39 348 0318 835"
                            : "Spedizione a casa: " +
                              order.infoUser?.address +
                              ", " +
                              order.infoUser?.cap +
                              " " +
                              order.infoUser?.city +
                              " (" +
                              order.infoUser?.provincia +
                              ")"}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-xs-12">
                      <div className="confirmation-sub-item">
                        <div className="title">Pagamento</div>
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {order.infoPayment.paymentMethod === 1
                            ? "Pagamento alla consegna"
                            : "Pagamento online"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </body>
    </html>
  );
}
