export const SET_USER_INFO = "SET_USER_INFO";
export const SET_FATTURAZIONE_INFO = "SET_FATTURAZIONE_INFO";

export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const SET_DISCOUNT = "SET_DISCOUNT";
export const SET_SHIPPING = "SET_SHIPPING";
export const INIT_CHECKOUT = "INIT_CHECKOUT";
export const SET_INNER_STATE = "SET_INNER_STATE";

export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_ERROR = "ORDER_ERROR";
export const ORDER_ACTIVE= "ORDER_ACTIVE";

export const initCheckout = (cartItems) => {
  return dispatch => {
    dispatch({
      type: INIT_CHECKOUT,
      payload: cartItems
    });
  }
}

export const setUserInfo = (userInfo) => {
  return dispatch => {
    dispatch({
      type: SET_USER_INFO,
      payload: userInfo
    });
  }
}
export const setFatturazioneInfo = (userInfo) => {
  return dispatch => {
    dispatch({
      type: SET_FATTURAZIONE_INFO,
      payload: userInfo
    });
  }
}

export const setCartItems = (cartItems) => {
  return dispatch => {
    dispatch({
      type: SET_CART_ITEMS,
      payload: cartItems
    });
  }
}

export const setDiscount = (discount) => {
  return dispatch => {
    dispatch({
      type: SET_DISCOUNT,
      payload: discount
    })

  }
}

export const setShipping = (shipping) => {
  return dispatch => {
    dispatch({
      type: SET_SHIPPING,
      payload: shipping
    });
  }
}
export const setInnerState = (innerState) => {
  return dispatch => {
    dispatch({
      type: SET_INNER_STATE,
      payload: innerState
    });
  }
}
