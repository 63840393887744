import uuid from "uuid/v4";

import {
  SHIPPING_TYPE_CONSEGNA,
  SHIPPING_TYPE_RITIRO_IN_SEDE,
} from "../../helpers/payment";
import {
  PRICE_SPEDIZIONE_CASERTA,
  PRICE_SPEDIZIONE_ITALIA, PRICE_SPEDIZIONE_MUGNANO,
  PRICE_SPEDIZIONE_NAPOLI,
} from "../../helpers/utils";
import {
  INIT_CHECKOUT,
  ORDER_ACTIVE,
  SET_CART_ITEMS,
  SET_DISCOUNT,
  SET_FATTURAZIONE_INFO,
  SET_INNER_STATE,
  SET_SHIPPING,
  SET_USER_INFO,
} from "../actions/checkoutActions";
import {getTotalCartItemsPriceWithDiscount} from "../../helpers/checkout";

const initState = {};

const calcolaSpedizione = (shippingType, checkoutData) => {
  //TODO: shipping price
  if (checkoutData.infoUser?.provincia === null) {
    return null;
  }
  if (shippingType === SHIPPING_TYPE_RITIRO_IN_SEDE || getTotalCartItemsPriceWithDiscount(checkoutData) >= 99.00  ) {
    return 0.0;
  }
  if (shippingType === SHIPPING_TYPE_CONSEGNA) {
    if (checkoutData.infoUser?.provincia === "Napoli") {
       
      if (checkoutData.infoUser?.cap?.replaceAll(" ","") === "80018"){
        return PRICE_SPEDIZIONE_MUGNANO;
      }
      return PRICE_SPEDIZIONE_NAPOLI;
    } else if (checkoutData.infoUser?.provincia === "Caserta") {
      return PRICE_SPEDIZIONE_CASERTA;
    } else {
      return PRICE_SPEDIZIONE_ITALIA;
    }
  }
};

const checkoutReducer = (state = initState, action) => {
  const checkout = state;
  //product = action.payload;
  if (action.type === INIT_CHECKOUT) {
    // for non variant products

    return { id: uuid(), cartItems: action.payload, innerState: ORDER_ACTIVE };
  }

  if (action.type === SET_USER_INFO) {
    // for non variant products
    return { ...checkout, infoUser: action.payload };
  }
  if (action.type === SET_FATTURAZIONE_INFO) {
    // for non variant products
    return { ...checkout, infoFatturazione: action.payload };
  }
  if (action.type === SET_CART_ITEMS) {
    return { ...checkout, cartItems: action.payload };
  }

  if (action.type === SET_DISCOUNT) {

    return { ...checkout, discount: action.payload };
  }

  if (action.type === SET_SHIPPING) {
    let shipping = {
      type: action.payload,
      price: calcolaSpedizione(action.payload, checkout),
    };
    return { ...checkout, shipping };
  }

  if (action.type === SET_INNER_STATE) {
    return { ...checkout, innerState: action.payload };
  }

  /* if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    } else {
      return cartItems.map(item =>
        item.cartItemId === product.cartItemId
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
    }
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
    return remainingItems(cartItems, product);
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter(item => {
      return false;
    });
  }

  if (action.type === ADD_DISCOUNT_CODE){
    
    return {...cartItems,discount:action.payload}
  }*/
  return state;
};

export default checkoutReducer;
