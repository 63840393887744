import { SET_CURRENCY } from "../actions/currencyActions";

const initState = {
  currencySymbol: "€",
  currencyName: "EUR",
  currencyRate: 1
};

const currencyReducer = (state = initState, action) => {
  if (action.type === SET_CURRENCY) {
    const currencyName = action.payload.currencyName;
    return {
      ...state,
      currencySymbol: "€",
      currencyRate: 1,
      currencyName
    };
   
  }

  return initState;
};

export default currencyReducer;
