import { getDiscountPrice } from "./product";

export const getTotalPriceCheckout = (checkoutData) => {
  if (checkoutData === undefined) {
    return 0;
  }
  const cartItemsPrice = getTotalCartItemsPrice(checkoutData?.cartItems);
  const shippingPrice = checkoutData?.shipping?.price || 0;
  const discountPrice = checkoutData?.discount?.valore || 0;
  return (Number(cartItemsPrice) + Number(shippingPrice) - Number(discountPrice)).toFixed(2);
};
export const getTotalCartItemsPrice = (cartItems) => {
  //
  let cartTotalPrice = 0;
  if (cartItems === undefined) {
    return 0;
  }
  cartItems.forEach((cartItem) => {
    const discountedPrice = getDiscountPrice(cartItem.price, cartItem.discount);
    const finalProductPrice = cartItem.price;
    const finalDiscountedPrice = discountedPrice;

    discountedPrice != null
        ? (cartTotalPrice += finalDiscountedPrice * cartItem.quantity)
        : (cartTotalPrice += finalProductPrice * cartItem.quantity);

    /* if (discountedPrice !== null) {
        (finalDiscountedPrice * cartItem.quantity).toFixed(2);
      } else {
        (finalProductPrice * cartItem.quantity).toFixed(2);
      }

      cartTotalPrice += discountedPrice * item.quantity;*/
  });

  return cartTotalPrice.toFixed(2);
};
export const getTotalCartItemsPriceWithDiscount = (checkoutData) => {
  if (checkoutData === undefined) {
    return 0;
  }
  const cartItemsPrice = getTotalCartItemsPrice(checkoutData?.cartItems);
  const discountPrice = checkoutData?.discount?.valore || 0;
  return (Number(cartItemsPrice)  - Number(discountPrice)).toFixed(2);
};
export const getCartItemsShippingPrice = (checkoutData) => {
  if (checkoutData === undefined) {
    return 0;
  }
  const cartItemsPrice = getTotalCartItemsPrice(checkoutData?.cartItems);
  const shippingPrice = checkoutData?.shipping?.price || 0;

  return Number(cartItemsPrice) + Number(shippingPrice);
};
