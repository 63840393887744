const wordToHex = {
  rosso: "#FF0000",
  verde: "#008000",
  blu: "#0000FF",
  giallo: "#FFFF00",
  arancione: "#FFA500",
  viola: "#800080",
  rosa: "#FFC0CB",
  marrone: "#A52A2A",
  nero: "#000000",
  bianco: "#FFFFFF",
  grigio: "#808080",
  azzurro: "#00FFFF",
  "blu scuro": "#002FA7",
  jeans: "#6F8FAF",
  "azzurro chiaro": "#ADD8E6",
  "verde chiaro": "#90EE90",
  "giallo chiaro": "#FFFFE0",
  "viola chiaro": "#E6E6FA",
  "rosa chiaro": "#FFB6C1",
  "grigio chiaro": "#D3D3D3",
  "azzurro scuro": "#00CED1",
  pistacchio: "#93C572",
  "viola scuro": "#800080",
  "rosa scuro": "#FF1493",
  "grigio scuro": "#A9A9A9",
  denim: "#1560BD",
  "verde militare": "#577906",
  fucsia: "#F400A1",
  argento: "#C0C0C0",
  prugna: "#660066",
  oro: "#CDA434",
  "verde acqua": "#256D7B",
  magenta: "#BA68C8",
  "giallo scuro": "#D4AF37",
  "bianco panna": "#FFFDD0",
  "marrone scuro": "#654321",
  "verde scuro": "#556B2F",
  beige: "#D2B48C",
};

const hexToWord = Object.fromEntries(
  Object.entries(wordToHex).map(([k, v]) => [v, k])
);

const toHex = (color) => wordToHex[color.toLowerCase().replace(/\s*$/, "")];

const PRICE_SPEDIZIONE_NAPOLI=5.0
const PRICE_SPEDIZIONE_SALERNO=7.0
const PRICE_SPEDIZIONE_MUGNANO=0.0
const PRICE_SPEDIZIONE_ITALIA=6.0
const PRICE_SPEDIZIONE_CASERTA=7.0

 const TYPE_SCONTO_PERCENTUALE=1
 const TYPE_SCONTO_VALORE=2
export { toHex, PRICE_SPEDIZIONE_NAPOLI,PRICE_SPEDIZIONE_ITALIA,PRICE_SPEDIZIONE_CASERTA,PRICE_SPEDIZIONE_MUGNANO,TYPE_SCONTO_VALORE,TYPE_SCONTO_PERCENTUALE };
