import { FETCH_BANNERS_SUCCESS } from "../actions/layoutActions";

const initState = {
  categoribannerses: []
};

const layoutReducer = (state = initState, action) => {
  if (action.type === FETCH_BANNERS_SUCCESS) {
    return {
      ...state,
      banners: action.payload
    };
  }

  return state;
};

export default layoutReducer;
